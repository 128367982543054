import classNames from 'classnames';
import { FC } from 'react';
import Skeleton from 'react-loading-skeleton';
import Container from '~/components/Container';
import SectionTitle from '~/components/SectionTitle';
import { styles as TextStyles } from '~/components/Text';
import { Identifiers } from '~/constants/enum';
import useClient from '~/hooks/useClient';
import useCmsBlocks from '~/hooks/useCmsBlocks';
import { replaceClassNames } from '~/utils/converters';
import styles from './NewCollection.module.scss';

interface NewCollectionProps {}

const NewCollection: FC<NewCollectionProps> = ({}) => {
    const isClient = useClient();

    const { cmsBlocks, loading } = useCmsBlocks([Identifiers.NewCollection]);

    const _content = cmsBlocks[Identifiers.NewCollection]?.content;

    // Define the compileStyle object
    const compileStyle: any = {
        NewCollection: styles,
        Text: TextStyles
    };

    const _title = cmsBlocks[Identifiers.NewCollection]?.title;

    if (!loading && !_content) return null;
    if (!isClient) return null;

    return (
        <Container rootClassName={styles.new_collection_wrapper} className={styles.new_collection}>
            <SectionTitle text={_title} centered />

            {_content ? (
                <div
                    className={styles.new_collection}
                    dangerouslySetInnerHTML={{ __html: replaceClassNames(_content, compileStyle) }}
                />
            ) : (
                <div className="">
                    <Skeleton className={classNames(styles.collection_item, 'mb-2')}></Skeleton>
                    <div className="grid grid-cols-1 gap-2 lg:grid-cols-2">
                        <Skeleton className={classNames(styles.collection_item, 'aspect-square')}></Skeleton>
                        <Skeleton className={classNames(styles.collection_item, 'aspect-square')}></Skeleton>
                    </div>
                </div>
            )}
        </Container>
    );
};

export default NewCollection;
